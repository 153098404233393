import type { ComponentType } from "react"

export function BalancedText(Component): ComponentType {
    return (props) => {
        return (
            <Component
                {...props}
                style={{
                    ...props.style,
                    textWrap: "balance",
                }}
            />
        )
    }
}
